import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import DefaultLayout from "/builds/everyone/dcdcweb-saturday/src/components/Layout/index.js";
import { Button } from '../components/Button';
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = DefaultLayout;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)} components={components}>


      <MDXTag name="h1" components={components}>{`Markdown Example`}</MDXTag>
      <MDXTag name="p" components={components}>{`This is a markdown "yes" (`}<MDXTag name="inlineCode" components={components} parentName="p">{`.md`}</MDXTag>{` or `}<MDXTag name="inlineCode" components={components} parentName="p">{`.mdx`}</MDXTag>{`) file that is being transformed with `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://gatsby-mdx.netlify.com/"
        }}><MDXTag name="inlineCode" components={components} parentName="a">{`gatsby-mdx`}</MDXTag></MDXTag>{`.`}</MDXTag>
      <MDXTag name="p" components={components}>{`What's cool about it is you can mix markdown with React components:`}</MDXTag>
      <p>
  <Button onClick={() => alert('You clicked the button! 🍪')}>I'm a React Button Component 🙌</Button>
      </p>
      <MDXTag name="p" components={components}>{`and the `}<MDXTag name="inlineCode" components={components} parentName="p">{`<ResetStyle />`}</MDXTag>{` component in the `}<MDXTag name="inlineCode" components={components} parentName="p">{`<Layout />`}</MDXTag>{` is providing a consistent style for all the pages with minimal effort`}</MDXTag>
      <MDXTag name="p" components={components}>{`You can also mix `}<MDXTag name="inlineCode" components={components} parentName="p">{`.js`}</MDXTag>{` and `}<MDXTag name="inlineCode" components={components} parentName="p">{`.md`}</MDXTag>{` or `}<MDXTag name="inlineCode" components={components} parentName="p">{`.mdx`}</MDXTag>{` files in your pages folder as needed`}</MDXTag>
           </MDXTag>;
  }

}
export const _frontmatter = {};
    